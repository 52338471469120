import ITransactionParser from "./ITransactionParser.mjs";

class ConsorsDepot extends ITransactionParser {
    info() {
        return "Consors Depot";
        // https://www.consorsbank.de/web-trading-service/api/user/accounts/348354353/orders?includeExecutions=true
    }

    isApplicable(jsonData) {
        try {
            let orders = jsonData.order
            if (orders) {
                return orders.length > 0;
            }
        } catch (error) {
            console.log("Consors Depot inapplicable")
        }
        return false;
    }

    formatDate(dateText) {
        const date = new Date(dateText);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(date.getDate()).padStart(2, '0');

        return `${month}/${day}/${year}`;
    }

    parseTransactions(jsonData) {
        const transactions = [];

        let orders = jsonData.order
        for (const baseOrder of orders) {
            baseOrder.execution.forEach(execution => {
                const t = {};

                t.orderno = execution.orderno
                t.quotation = execution.quotation
                t.quotationCurrency = execution.quotationCurrency
                t.quotationDateTime = execution.quotationDateTime
                t.securityAmountExecuted = execution.securityAmountExecuted
                t.valueDate = execution.valueDate
                t.securityIsin = baseOrder.securityIsin
                t.securityName = baseOrder.securityName
                t.securityWkn = baseOrder.securityWkn
                t.clearingAccountno = baseOrder.clearingAccountno
                t.ordertype = baseOrder.ordertype

                transactions.push(t)
            });
        }

        return transactions
    }

    toQif(transactions) {
        const qifTransactions = transactions.flatMap(t => {
            console.log(t.securityAmountExecuted * t.quotation)
            t.commissionRate = this.getCommissionRate(t)

            t.ordertotal = t.securityAmountExecuted*t.quotation
            t.commission = t.ordertotal*t.commissionRate
            t.total = t.ordertotal+t.commission
            
            return [
                `D${this.formatDate(t.quotationDateTime)}`,
                `N${this.convertOrderType(t.ordertype)}`,
                `Y${t.securityName}`,
                `I${t.quotation}`,
                `Q${t.securityAmountExecuted}`,
                `MOrder ${t.orderno}`,
                `O${t.commission}`,
                `T${t.total}`,
                `L[consors Verrechnungskonto]`, // TODO enable config
                `^`
            ]
        })

        const qifHeader = [
            `!Account`,
            `Nconsors Verrechnungskonto:consors Depot`,
            `DMy Consors Depot`,
            `TInvst`,
            `^`,
            `!Type:Invst`
        ]

        const qifContent = qifHeader.concat(qifTransactions).join("\n")

        return qifContent
    }

    roundToTwo(num) {
        return +(Math.round(num + "e+2")  + "e-2");
    }

    convertOrderType(ordertype) {
        switch (ordertype) {
            case "B":
                return "Buy"
            default:
                throw new Error("Unsupported ordertype: " + ordertype)
        }
    }

    getCommissionRate(transaction) {
        let rate = 0.0;
        switch (transaction.securityName) {
            case "ISHSV-MSCI ACWI DL A":
                rate = 0.015;
                break;
            case "UBISOFT ENTMT IN.EO-,0775":
                rate = 0.018;
                break;
            default:
                throw Error("Unknown commission rate for: " + transaction.securityName)
        }
        return rate;
    }

    formatDate(dateText) {
        const date = new Date(dateText);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(date.getDate()).padStart(2, '0');

        return `${month}/${day}/${year}`;
    }

}

export default ConsorsDepot;