import ITransactionParser from "./ITransactionParser.mjs";

class KlarnaCard extends ITransactionParser {
    info() {
        return "Klarna Card";
    }

    isApplicable(jsonData) {
        try {
            if (jsonData.data?.activityList?.items) {
                return jsonData.data?.activityList?.items.length > 0;
            }
        } catch (error) {
            console.log("Klarna plugin inapplicable")
        }
        return false;
    }

    formatDate(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    parseTransactions(jsonData) {
        const rootData = jsonData.data;
        const activityList = rootData.activityList
        const items = activityList.items

        const transactions = [];

        items.forEach(item => { // might wanna slice(0, 1) while debugging
            //console.log(item)
            const result = {};

            result.title = item.title;
            result.uniqueId = item.uniqueId;
            result.createdAt = item.createdAt;
            result.createdAtDate = new Date(item.createdAt);
            result.date = this.formatDate(result.createdAtDate)
            result.rootCreatedAt = item.rootCreatedAt;
            result.rootCreatedAtDate = new Date(item.rootCreatedAt);


            result.statusTitle = item.status.title;
            result.statusSubtitle = item.status.subtitle ?? "";
            result.fundingSources = item.status?.details?.userFundingSources?.map(fundingSrc => `${fundingSrc.altText} ${fundingSrc.maskedNumber}`) ?? "";

            //result.amount = item.amount.amount.replace('€', '').replace(',', '.').trim()
            result.amount = item.amount.currentAmount / 100.0;

            if (!item.amount.hasPlus) {
                result.amount = -result.amount;
            }

            result.amountCurrency = item.amount.currency
            transactions.push(result)
        });

        return transactions

    }

    toQif(transactions) {
        const qifHeader = [
            `!Account`,
            `NKlarna Card`,
            `DMy Klarna purchases`,
            `TCCard`,
            `^`,
            `!Type:CCard`
        ]

        const qifTransactions = transactions.flatMap(t => [
            `D${t.date}`,
            `T${t.amount}`,
            `M${t.statusTitle} ${t.statusSubtitle} ${t.fundingSources}`,
            `P${t.title}`,
            `^`
        ])

        const qifContent = qifHeader.concat(qifTransactions).join("\n")

        return qifContent
    }




}

export default KlarnaCard;